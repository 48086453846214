.az-button {
  @apply flex justify-center px-6 py-3 font-helvetica-az font-bold text-sm rounded-3xl cursor-pointer uppercase;

  border: 2px solid;

  &--primary {
    @apply bg-primary text-white;

    border-color: #830051;

    &:hover {
      @apply bg-primary-dark;

      border-color: #6D0043;
    }

    &:active {
      @apply bg-primary-dark;

      border-color: #6D0043;
    }

    &:disabled {
      @apply bg-gray-extra-dark opacity-70 pointer-events-none;

      border-color: #545454;
    }
  }

  &--secondary {
    @apply text-gray-dark;

    border-color: #8E9393;

    &:hover {
      @apply text-gray-extra-dark;

      border-color: #545454;
    }

    &:active {
      @apply text-gray-extra-dark;

      border-color: #545454;
    }

    &:disabled {
      @apply opacity-70 pointer-events-none;

      border-color: #545454;
    }
  }

  &--tertiary {
    @apply bg-white bg-opacity-40 text-white;

    &:hover {
      @apply bg-opacity-60;
    }

    &:active {
      @apply bg-opacity-60;
    }

    &:disabled {
      @apply bg-gray-extra-dark opacity-70;
    }
  }

  &__icon {
    @apply ml-2 self-center;
  }

  &__content {
    @apply flex justify-between;
  }

  &__spinner {
    @apply w-5;
  }
}

@media (max-width: 767px) {
  .az-button__add {
    border-radius: 100%;
    width: 50px !important;
    height: 50px !important;
    padding: 0px;
  }
  .az-button__label--add-mobile {
    display: none;
  }
  .az-button__icon--add-mobile {
    margin: auto;
  }
}
