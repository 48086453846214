.az-score-modal {
  @apply flex flex-col justify-center w-full space-y-6;

  &__title {
    @apply font-lexia font-bold text-lg text-primary self-start;
  }

  &__scores {
    @apply flex justify-between;
  }

  &__element-container {
    @apply w-5/12;
  }

  &__buttons {
    @apply flex justify-between;
  }

  &__error {
    @apply text-error font-helvetica-az font-bold text-sm;
  }
}
