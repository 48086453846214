.az-admin-medical-centers {
  @apply flex-col;

  &__button-container {
    @apply w-full flex justify-end mb-14 ml-8;

    max-width: 240px;
  }

  &__button-text {
    @apply font-helvetica-az font-bold text-dark text-sm underline text-left;

    &:hover {
      @apply opacity-70;
    }
  }

  &__upload-button {
    @apply flex justify-center px-6 pt-3 font-helvetica-az font-bold text-sm rounded-3xl cursor-pointer uppercase bg-primary text-white;

    border: 2px solid;
    border-color: #830051;

    input[type="file"] {
      display: none;
    }

    &:hover {
      @apply bg-primary-dark;

      border-color: #6D0043;
    }

    &:active {
      @apply bg-primary-dark;

      border-color: #6D0043;
    }

    &:disabled {
      @apply bg-gray-extra-dark opacity-70 pointer-events-none;

      border-color: #545454;
    }
  }

  &__spinner {
    ///margin is used to increase parent height
    @apply w-8 block my-16 mx-auto;
  }
}
