.az-footer {
  @apply font-helvetica-az flex flex-col lg:flex-row bg-dark px-7 lg:px-24 py-10;

  &__element {
    @apply flex flex-col w-full self-center text-left text-gray-light;

    &--info {
      @apply lg:w-4/12 justify-between lg:self-start mb-10 lg:mb-0;
    }

    &--ref {
      @apply lg:w-5/12 lg:self-auto lg:justify-between lg:pl-10 mb-10 lg:mb-0;
    }

    &--contact-us {
      @apply lg:w-3/12 justify-between lg:self-auto lg:text-right;
    }
  }

  &__logo {
    width: 230px;
    height: 55px;
  }

  &__title {
    @apply font-lexia mb-5 text-xl;
  }

  &__description {
    @apply text-sm mb-10;
  }

  &__info-site {
    @apply flex flex-col items-start font-bold lg:border-l-2 lg:border-solid lg:border-gray-light lg:pb-8;
  }

  &__text {
    @apply mb-10 lg:mb-1 lg:ml-4 text-sm;

    &:hover {
      @apply text-gray-dark underline;
    }
  }

  &__links {
    @apply flex flex-wrap lg:flex-nowrap justify-between xl:whitespace-nowrap;
  }

  &__link {
    @apply pt-3 lg:mr-3 w-6/12 lg:w-auto text-xs;

    &:hover {
      @apply text-gray-dark underline;
    }
  }

  &__icons {
    @apply flex flex-row justify-between lg:justify-end mb-14;
  }

  &__icon {
    @apply lg:ml-6 w-10 h-10 rounded-full;

    &:hover {
      @apply bg-gray-dark underline;
    }
  }

  &__facebook {
    @apply bg-no-repeat bg-center bg-cover;

    background-image: var(--icon-facebook);
  }

  &__linkedin {
    @apply bg-no-repeat bg-center bg-cover;

    background-image: var(--icon-linkedin);
  }

  &__twitter {
    @apply bg-no-repeat bg-center bg-cover;

    background-image: var(--icon-twitter);
  }

  &__id-text {
    @apply text-xs;
  }
}
