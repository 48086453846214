:root {
  // Logo
  --logo-full: url('assets/LOGO_eAZyREF_colore_bianco.png');
  --logo-white: url('assets/AZ-Logo-w.png');
  --logo-white-yellow: url('assets/LOGO_eAZyREF_orizzontale_bianco.png');

  // Images
  --bg-az1: url('assets/Monogramma_bg_1.png');
  --bg-az2: url('assets/Monogramma_bg_2.png');
  --icon-facebook: url('assets/icon-facebook.png');
  --icon-linkedin: url('assets/icon-linkedin.png');
  --icon-twitter: url('assets/icon-twitter.png');
  --video-placeholder: url('assets/AZ-Video-Frame.jpg');
}
