.az-answer-choice {
  @apply h-auto w-full;

  &__header {
    @apply flex justify-between items-center pb-3 mb-8 border-solid border-b border-gray;
  }

  &__title {
    @apply font-helvetica-az font-bold text-sm text-dark;
  }

  &__button-text {
    @apply font-helvetica-az font-bold text-xs underline text-gray-dark;

    &:hover {
      @apply opacity-70;
    }
  }

  &__form-container {
    @apply flex flex-col w-full space-y-10;
  }

  &__inputs-row {
    @apply flex space-x-5;
  }

  &__input {
    @apply w-full;
  }
}
