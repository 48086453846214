.az-highlight-section {
  @apply pt-4;

  &__heading {
    @apply font-helvetica-az font-normal text-xl md:text-3xl text-dark mt-12 text-center md:mx-16 lg:mx-36;
  }

  &__blocks {
    @apply flex flex-wrap items-center justify-center;
  }

  &__block {
    @apply w-full md:w-1/3;
  }
}
