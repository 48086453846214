@import './src/utils/color-variants';

.az-input-label {
  @apply flex items-start font-medium font-helvetica-az text-base;

  @include color-variants(('color'));

  &--error {
    @apply text-error;
  }

  &--bold {
    @apply font-bold;
  }
}
