.az-header {
  @apply w-full bg-gray-light;

  &--colored {
    @apply bg-gray-light;
  }

  &--transparent {
    @apply bg-transparent;
  }

  &__gradient-bar {
    @apply w-full h-6 bg-gradient-to-r from-primary to-secondary absolute top-0 mb-6;
  }

  &__container {
    @apply flex h-24 lg:h-32 justify-center lg:justify-between pt-2 pb-4 lg:pt-6 px-10 lg:px-24 relative z-20;
  }

  &__logo-link {
    @apply lg:mr-8 mb-2 lg:mb-4;

    &--disabled {
      @apply hidden;
    }
  }

  &__logo {
    @apply lg:mr-8 mb-2 lg:mb-4;

    margin-top: 3.5rem;
    object-fit: contain;
    height: 60px;
    width: 150px;

    @screen lg {
      margin-top: 4rem;
      height: 87px;
      width: 219px;
    }
  }

  &__mobile-menu {
    @apply lg:hidden absolute left-8;

    top: 48px;
  }

  &__homepage-mobile-menu {
    @apply lg:hidden absolute right-8;

    top: 48px;
  }

  &__desktop-menu {
    @apply flex items-center;
  }

  &__access {
    @apply hidden lg:flex font-helvetica-az font-normal text-base text-dark self-center mt-14;

    &--light {
      @apply text-white;
    }
  }

  &__link {
    @apply hidden lg:flex font-helvetica-az font-normal text-base text-dark self-center px-3 mt-14;

    &--light {
      @apply text-white;
    }

    &:hover {
      @apply underline;
    }

  }
  &__button {
    @apply hidden lg:flex;
  }

  &__profile {
    @apply hidden lg:flex mt-14;
  }

  &__login {
    @apply hidden lg:flex;
  }

  &__logout {
    @apply ml-8 font-helvetica-az font-normal underline text-dark text-base self-center;

    &--light {
      @apply text-white;
    }
  }

  &__burger-menu {
    @apply absolute left-0 top-0 w-full lg:hidden z-10;
  }

}
