:root {
  // colors
  --primary-color: #0a0a0a;
  --secondary-color: #05bec6;
  --error-color: #f00029;
  --success-color: #18a957;

  // logo
  --logo: url('./assets/logo.svg');

  // font sizes
  --font-smaller: 10px;
  --font-small: 12px;
  --font-regular: 16px;
  --font-big: 18px;
  --font-bigger: 36px;

  // font family
  --font-family: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen, oxygen-sans, ubuntu, cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', helvetica, arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  // body
  --body-background-color: #fff;
  --body-color: var(--primary-color);

  //button
  --button-primary-background-color: var(--primary-color);
  --button-primary-color: var(--secondary-color);
  --button-primary-border-color: var(--secondary-color);
  --button-primary-hover-background-color: lighten(var(--button-primary-background-color), 16.08);
  --button-primary-hover-color: var(--secondary-color);
  --button-primary-hover-border-color: lighten(var(--button-primary-background-color), 16.08);
}
