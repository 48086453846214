.az-questionnaire-card {
  @apply flex flex-col justify-center h-auto w-72 bg-white py-9 px-10 rounded-2xl shadow-xl;

  &__title {
    @apply font-lexia font-bold text-lg text-dark text-center;
  }

  &__subtitle {
    @apply font-lexia font-medium text-sm text-dark text-center mt-2;
  }

  &__button-wrapper {
    @apply my-7 px-8;
  }

  &__button-texts {
    @apply flex justify-center whitespace-nowrap;
  }

  &__button-text {
    @apply font-helvetica-az font-bold text-sm underline;

    &:hover {
      @apply opacity-70;
    }

    &--gray {
      @apply text-gray-dark;
    }

    &--primary {
      @apply text-primary pr-2 border-solid border-r border-gray-dark;
    }
  }
}
