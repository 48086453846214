.az-result-questionnaire {
  &__patient-data {
    @apply mt-12 mb-16;
  }

  &__title {
    @apply font-lexia font-bold text-dark self-center text-center mb-8;

    font-size: 22px;
  }

  &__result {
    @apply font-helvetica-az font-light text-2xl md:text-lg text-dark self-center text-center mb-14;

    &--primary {
      @apply font-bold text-primary;
    }
  }

  &__result-box {
    @apply flex flex-col lg:flex-row justify-around space-y-6 lg:space-y-0 lg:space-x-4 mb-24;
  }

  &__button {
    @apply flex justify-center mx-auto w-60 mb-28;
  }
}
