.az-active-questionnaire {

  &__patient-data {
    @apply my-11;
  }

  &__questionnaire-data {
    @apply lg:w-8/12 text-center lg:mx-auto;
  }

  &__titles-container {
    @apply self-center space-y-3 mb-9;
  }

  &__title {
    @apply font-lexia font-bold text-dark;

    font-size: 22px;
  }

  &__subtitle {
    @apply font-helvetica-az font-normal text-base text-dark;

    margin-top: 26px !important;
    margin-bottom: 50px !important;
  }

  &__instructions-container {
    @apply mb-28;
  }

  &__instructions-title {
    @apply font-helvetica-az font-bold text-lg text-dark;
  }

  &__instructions {
    @apply font-helvetica-az font-normal text-base text-dark;
  }

  &__buttons {
    @apply flex self-center justify-center mb-12 space-x-5;
  }

  &__button {
    @apply w-40;
  }

  &__references {
    @apply flex self-center justify-center font-helvetica-az text-xs text-gray-dark mb-12;
  }
}
