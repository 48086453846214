.az-admin-login {

  &__logo-link {
    @apply mt-24 mb-20 self-center;

    height: 150px;
    width: 285px;
  }

  &__logo {
    @apply w-full h-full;
  }

  &__content {
    @apply flex flex-col justify-center;
  }

  &__inputs {
    @apply flex flex-col space-y-6 lg:flex-row lg:space-y-0 lg:self-center lg:space-x-12;
  }

  &__password-content {
    @apply flex flex-col lg:w-64;
  }

  &__link {
    @apply font-helvetica-az font-bold text-sm underline;

    &--dark-gray {
      @apply text-gray-dark mt-6 lg:self-end;
    }

    &--primary {
      @apply text-primary self-center mb-20;
    }
  }

  &__cta {
    @apply flex justify-center bg-primary text-white mt-24 mb-40 self-center;
  }
}
