$ratio-padding-map: (
  '1:1': 100%,
  '4:3': 75%,
  '16:9': 56.25%,
);

@mixin ratio-classes {
  @each $ratio, $padding in $ratio-padding-map {
    $colon-idx: str-index($ratio, ':');

    &--ratio-#{str-slice($ratio, 1, $colon-idx - 1)}-#{str-slice($ratio, $colon-idx + 1)} {
      padding-bottom: $padding;
    }
  }
}
