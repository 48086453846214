.az-data-table {
  @apply w-full;

  &__head {
    @apply border-b-4 border-solid border-gray;
  }

  &__patients-data-row {
    @apply border-solid border-gray border-b;
  }

  &__row {
    @apply cursor-pointer;

    &:hover {
      @apply bg-hover-gray underline;
    }
  }

  &__no-results {
    @apply text-center mt-8 text-dark;
  }

  &__table-container {
    max-width: 100vw;
    overflow: scroll;
  }
}
