.az-admin-page {
  @apply grid bg-primary absolute top-0 bottom-0 left-0 right-0;

  grid-template-columns: 1fr 4fr;
  grid-template-rows: 2fr auto;

  &__sidebar {
    @apply col-span-1 row-span-2;
    min-height: 100vh;
  }

  &__header {
    @apply flex justify-between h-28 border-solid border-b border-gray px-4 py-6;
  }

  &__container {
    @apply px-4 py-8;
  }

  &__title {
    @apply text-2xl font-lexia font-bold text-primary pb-2.5;
  }

  &__breadcrumb {
    @apply mt-3;
  }

  &__header-actions {
    @apply flex justify-center space-x-10;
  }

  &__cta {
    @apply flex items-center;

    min-width: 140px;
  }

  &__button-text {
    @apply inline-block whitespace-nowrap font-helvetica-az font-bold text-xs text-dark underline;

    &:hover {
      @apply opacity-70;
    }
  }

  &__main {
    @apply col-start-2 row-span-1 bg-gray-light;
  }

  &__alert {
    @apply top-4 right-3 left-3 fixed z-50;
  }

  &__footer {
    @apply col-start-2 col-end-3 row-auto;
  }
}
