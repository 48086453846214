.az-admin-footer {
  @apply flex justify-end w-full h-auto bg-dark px-20 py-3;

  &__logo-wrapper {
    @apply flex justify-center w-4/12;
  }

  &__logo {
    @apply h-12 self-center;

    width: 200px;
  }

  &__id-text {
    @apply flex w-4/12 justify-end items-end text-white text-xs font-helvetica-az font-light text-right;
  }
}
