.az-data-table--pathologies {
  $root-selector: '.az-data-table';

  #{$root-selector}__pathologies-data-row {
    @apply w-full border-solid border-gray border-b;

    &:hover {
      @apply bg-hover-gray underline;
    }
  }
}
