.az-modal {
  @apply fixed w-screen h-screen flex justify-center items-start bg-dark-alpha transition-opacity opacity-100 duration-300 z-20;

  &--transparent {
    @apply opacity-0;
  }

  &__content {
    @apply relative bg-white mt-20 rounded-3xl py-8 px-12 w-5/12;
  }

  &__close {
    @apply absolute right-7 top-6 text-gray;

    &:hover {
      @apply text-dark;
    }
  }
}

@media(max-width: 1023px) {
  .az-modal__content {
    margin-top: 64px;
    padding-bottom: 72px;
    max-height: 100vh;
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .az-modal {
    top: 0;
  }

  .az-modal__content {
    width: 100vw;
    height: 100vh;
    margin-top: 20%;
    padding-bottom: 24%;
    overflow: auto;
  }
}
