.az-mmg-profile {
  @apply mt-12 mb-60;

  &__container {
    @apply flex flex-col md:flex-row w-full md:w-8/12 mx-auto space-x-0 md:space-x-8 mt-4;
  }

  &__container-checkboxes {
    @apply flex flex-col w-full md:w-8/12 mx-auto mt-12;
  }

  &__checkbox {
    @apply my-4 ml-0;
  }

  &__disclaimer-text {
    @apply flex flex-col w-full md:w-8/12 mx-auto text-center mb-16;
  }

  &__link-container {
    @apply flex flex-col w-full md:w-8/12 mx-auto mt-8;
  }

  span {
    @apply font-helvetica-az font-bold text-dark text-sm;
  }

  &__link {
    @apply font-helvetica-az font-bold text-dark text-sm underline text-left;

    &:hover {
      @apply opacity-70;
    }

    &--primary {
      @apply text-primary;
    }
  }

  &__spinner {
    @apply w-8 block my-16 mx-auto;
  }

  &__checkbox {
    @apply mb-4;
  }

  &__button {
    @apply mt-16 mx-auto;

    max-width: 220px;
  }
}

