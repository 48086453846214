@import './src/utils/color-variants';

.az-nike-logo {
  @apply w-full;

  @include color-variants('color');

  path {
    @apply fill-current;
  }
}
