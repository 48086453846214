.az-radio-answer {
  @apply flex space-x-2 p-6 bg-white rounded-2xl shadow-xl items-center w-full h-auto cursor-pointer border-solid border-transparent border-2 text-left;

  &--checked {
    @apply border-solid border-2 border-primary bg-primary-alpha;
  }

  &--disabled {
    @apply pointer-events-none lg:pl-16;
  }

  &--HIGH {
    @apply pointer-events-none border-solid border-2 border-highRisk bg-highRisk-alpha lg:pl-16;
  }

  &--MEDIUM_HIGH {
    @apply pointer-events-none border-solid border-2 border-medHighRisk bg-medHighRisk-alpha lg:pl-16;
  }

  &--MEDIUM_LOW {
    @apply pointer-events-none border-solid border-2 border-medLowRisk bg-medLowRisk-alpha lg:pl-16;
  }

  &--LOW {
    @apply pointer-events-none border-solid border-2 border-lowRisk bg-lowRisk-alpha lg:pl-16;
  }
}
