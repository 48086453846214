.az-pagination {
  @apply flex justify-center flex-wrap;

  &__page-button {
    @apply font-helvetica-az text-sm text-gray-dark px-6 border-gray-dark mb-2;
    border-right: 1px solid;
    &:last-child {
      border: none;
    }
    &--primary {
      @apply text-primary font-bold border-gray-dark;
    }
  }
}
