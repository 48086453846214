.az-terms-and-conditions {

  &__title {
    @apply font-lexia font-bold text-3xl text-primary mb-12 mt-9;
  }

  &__content {
    @apply font-helvetica-az text-base text-dark mb-64;
  }

  &__link {
    @apply underline text-blue-500;
  }
}
