.az-admin-manage-detail {

  &__row-wrapper {
    @apply w-full flex mb-8 space-x-6;
  }

  &__row-wrapper-image {
    @apply w-full flex mb-8;
  }

  &__radio-title {
    @apply mb-5 text-dark text-base;
  }

  &__radio-label {
    @apply pl-4 text-dark text-sm;
  }

  &__drop {
    @apply w-4/12;
  }

  &__description {
    @apply w-4/12;
  }

  &__button-text-wrapper {
    @apply mt-2;
  }

  &__button {
    @apply font-helvetica-az uppercase text-white;

    display: flex;
    justify-content: center;
    font-size: small !important;
    font-weight: bold !important;
    background: #830051 !important;
    padding: 0.75rem 1.5rem !important;

    &:hover {
      background: #6D0043 !important;
    }
  }

  input[type='radio'] {
    width: 18px;
    height: 18px;
    top: 3px;
    position: relative;
  }
}
