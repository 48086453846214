.az-hero-banner {
  @apply pt-32 lg:pt-52 pb-36 lg:pb-72;

  &__content {
    @apply container;
  }

  &__marker {
    @apply w-10;
  }

  &__title {
    @apply font-lexia font-bold text-3xl lg:text-5xl text-white mt-4 lg:mt-6;
  }

  &__subtitle {
    @apply font-helvetica-az font-normal text-xl lg:text-3xl text-white mt-9;
  }

  @media (max-width: 767px) {
    &__subtitle {
      font-size: 22px;
    }
  }
}
