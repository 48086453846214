.az-admin-medical-centers-detail {

  &__row-wrapper {
    @apply w-full flex mb-8 space-x-6;
  }

  &__autofill {
    @apply flex mt-11 w-2/12 underline text-dark text-sm font-bold;

    &:hover {
      @apply opacity-70;
    }
  }

  &__opening-date-row {
    @apply flex w-full items-center mb-4;
  }

  &__cell {
    @apply w-2/12 mr-3;
  }

  &__inputs-container {
    @apply w-8/12 flex;
  }

  &__input-wrapper {
    @apply flex border-solid border border-gray justify-between self-start rounded-lg pr-3 bg-white items-center mx-4;

    max-height: 45px;

    &--error {
      @apply border-error bg-error-alpha;
    }

    &--disabled {
      @apply bg-gray-light text-gray-dark;
    }
  }

  &__input {
    @apply w-full px-3 py-3 bg-transparent text-dark text-sm items-center font-helvetica-az font-normal outline-none;
  }

  &__separator {
    @apply w-0;

    outline: 1px solid #DDDEDE;
    margin-top: 8px;
    height: 30px;
  }

  &__drop {
    @apply w-8/12;
  }

  &__description {
    @apply w-8/12;
  }

  &__opening-hours {
    @apply flex items-start font-bold font-helvetica-az text-lg text-dark mt-6;
  }

  &__day {
    @apply font-medium font-helvetica-az text-base text-dark;
  }

  &__checkbox-label {
    @apply font-medium font-helvetica-az text-sm text-dark;
  }
}
