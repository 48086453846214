.az-data-table--contents {
  $root-selector: '.az-data-table';

  #{$root-selector}__contents-data-row {
    @apply w-full border-solid border-gray border-b;

    &:hover {
      @apply bg-hover-gray underline;
    }
  }

  #{$root-selector}__data-cell {
    @apply cursor-pointer;

    &--disabled {
      @apply cursor-auto;
    }
  }
}
