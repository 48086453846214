.az-result-box {
  @apply flex flex-col justify-between h-auto w-full bg-white p-10 rounded-3xl text-center text-dark;

  &__score {
    @apply font-lexia text-3xl font-bold;

    &-highlight {
      @apply text-5xl;

      &--red {
        @apply text-highRisk;
      }

      &--orange {
        @apply text-medHighRisk;
      }

      &--yellow {
        @apply text-medLowRisk;
      }

      &--green {
        @apply text-lowRisk;
      }
    }
  }

  &__description {
    @apply font-helvetica-az text-2xl mt-5;

    &-highlight {
      @apply font-lexia font-bold;

      &--red {
        @apply text-highRisk;
      }

      &--orange {
        @apply text-medHighRisk;
      }

      &--yellow {
        @apply text-medLowRisk;
      }

      &--green {
        @apply text-lowRisk;
      }
    }
  }
}
