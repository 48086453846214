.az-admin-edit-password {

  &__inputs-wrapper {
    @apply justify-center;
  }

  &__input {
    max-width: 300px;
  }
}
