.az-checkbox {
  @apply flex space-x-4;

  &__input-wrapper {
    @apply w-6 h-6;
  }

  &__input {
    @apply relative top-6 hidden;

    + span {
      @apply cursor-pointer flex justify-center w-6 h-6 bg-transparent rounded-lg border-solid border border-gray-dark;
    }

    &:checked {

      + span {
        @apply border-primary bg-primary;

        &::before {
          @apply bg-primary;
        }
      }
    }
  }

  &__thick {
    @apply text-white relative left-1.5;

    bottom: 1.6rem;
  }

  &__label {
    @apply flex justify-start self-center font-helvetica-az text-base font-normal text-dark mt-0.5;

    &--checked {
      @apply font-bold;
    }
  }
}
