.az-add-patient {
  @apply w-full px-4;

  &__error {
    @apply text-sm text-highRisk mb-6;
  }

  &__title {
    @apply font-lexia font-bold text-lg text-primary;
  }

  &__fieldset {
    @apply py-10;
  }

  &__field {

    &--email {
      @apply mt-6;
    }
  }

  &__container-checkboxes {
    @apply flex flex-col w-full md:w-8/12 mx-auto mt-12;
  }

  &__checkbox {
    @apply my-4 ml-0;
  }

  &__cta-container {
    @apply flex flex-col-reverse lg:flex-row justify-between items-center;
  }

  &__cta {

    &--delete {
      @apply mt-4 lg:mt-0;
    }

    &--submit {
      @apply lg:ml-2;
    }
  }
}
