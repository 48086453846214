.az-signup {

  &__title {
    @apply font-lexia font-bold text-2xl lg:text-3xl my-10 text-primary;
  }

  &__content {
    @apply flex flex-col justify-center mt-12;
  }

  &__inputs-container {
    @apply flex flex-col lg:w-8/12 lg:self-center lg:items-center;
  }

  &__inputs-wrapper {
    @apply flex flex-col lg:flex-row lg:space-x-12 lg:w-full px-8;
  }

  &__input {
    @apply lg:w-full mb-7;
  }

  &__hint-password {
    @apply flex justify-center text-center font-helvetica-az font-light text-sm text-gray-dark lg:w-full italic;
  }

  &__checkboxes {
    @apply w-full space-y-4 mt-10 lg:flex lg:flex-col lg:self-center lg:w-8/12 px-8;
  }

  &__button-wrapper {
    @apply self-center w-8/12;

    max-width: 200px;
  }

  &__button {
    @apply flex justify-center bg-primary text-white mt-14 mb-20 self-center;
  }
}
