/// Maps colors set in tailwind.config.js
/// @see https://tailwindcss.com/docs/customizing-colors#custom-colors
$color-list: ('primary', 'secondary', 'tertiary', 'gray-light', 'gray', 'gray-dark', 'gray-extra-dark', 'error', 'error-alpha', 'dark', 'white');
/// Maps rule-list parameter values to tailwind rule prefixes
$rule-to-tailwind-prefix-map: (
  'background-color': 'bg',
  'color': 'text',
);

/// Creates colored variants for the provided selector.
///
/// @example providing no selectors it will produce for the selector from which the mixin will be called
/// if you call this mixin from a .foo selector, it will produce variants like .foo--primary, .foo--secondary, etc.
///
/// @example providing $root-selector and $child-selector it will produce child-selector nested in root-selector color variants
/// given .foo as $root-selector and .bar as $child-selector
/// it will produce color variants like .foo--primary .bar, .foo--secondary .bar, etc.
/// to apply color rules
///
/// @param {map} $rules-list        - List of rules to applied
/// @param {string} $selector       - Main selector for which variants will created
/// @param {string} $child-selector - If provided, $child-selector will be the target of variants creation
@mixin color-variants($rules-list, $root-selector:null, $child-selector: null) {
  @each $rule in $rules-list {
    $tailwind-prefix: map-get($rule-to-tailwind-prefix-map, $rule);

    @each $color in $color-list {
      @if ($root-selector and $child-selector) {
        @at-root #{$root-selector}--#{$color} #{$child-selector} {
          @apply #{$tailwind-prefix}-#{$color};
        }
      }

      @else {
        &--#{$color} {
          @apply #{$tailwind-prefix}-#{$color};
        }
      }
    }
  }
}
