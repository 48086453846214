@import '../AddPatient/AddPatient.scss';

.az-delete-modal {
  &__text {
    @apply font-helvetica-az font-medium text-sm text-dark my-6;
  }

  &__cta {
    @apply mx-auto;
  }
}
