.az-question-accordion {
  @apply flex flex-col items-center w-full h-auto p-4 bg-white rounded-2xl shadow-xl transform duration-700 ease-in-out;

  &--error {
    @apply border-solid border border-error;
  }

  &__header {
    @apply flex justify-between w-full items-center pb-3 border-solid border-b border-gray;
  }

  &__title {
    @apply font-helvetica-az font-bold text-sm text-primary;
  }

  &__header-actions {
    @apply flex space-x-10;
  }

  &__button-text {
    @apply font-helvetica-az font-bold text-xs underline text-gray-dark;

    &:hover {
      @apply opacity-70;
    }
  }

  &__inputs {
    @apply flex space-x-5 w-full mt-8 items-center;
  }

  &__answer {
    @apply mt-8;
  }

  &__add-new-answer {
    @apply flex justify-center w-80 my-20;
  }
}
