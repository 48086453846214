.az-new-questionnaire-modal {

  &__title {
    @apply font-lexia font-bold text-lg text-primary;
  }

  fieldset {
    @apply flex flex-col justify-center py-10 space-y-6;
  }

  &__spinner {
    ///margin is used to increase parent height
    @apply w-8 block my-16;
  }

  &__field {
    @apply w-full flex items-center justify-start;
  }

  &__cta-container {
    @apply flex justify-between items-center space-x-4;
  }
}
