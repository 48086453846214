.az-admin-pathologies {
  @apply flex-col;

  &__button-container {
    @apply w-full flex justify-end mb-14;

    max-width: 220px;
  }

  &__spinner {
    ///margin is used to increase parent height
    @apply w-8 block my-16 mx-auto;
  }
}
