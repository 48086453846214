.az-status-label {
  @apply rounded-2xl py-1 px-3 inline-block;

  min-width: 100px;

  &--active {
    @apply bg-active-alpha;

    span {
      @apply text-active;
    }
  }

  &--inactive {
    @apply bg-gray;

    span {
      @apply text-gray-dark;
    }
  }

  span {
    @apply block font-helvetica-az text-sm font-bold text-center;
  }
}
