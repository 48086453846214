@import '../../../utils/color-variants';

.az-table-data {
  @apply font-helvetica-az text-base text-left;

  @include color-variants('color');

  &__icon {
    @apply mt-2;
  }

  &--boxed {
    @apply py-5 px-2.5;
  }

  &--center {
    @apply text-center;
  }
}
