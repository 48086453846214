.az-questionnaire {
  &__heading-cta {
    @apply flex items-end;
    flex-wrap: wrap;
  }

  &__page-min-height {
    width: 100%;
    // min-height: 30vh;
  }
}
