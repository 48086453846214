#map {
  height: 600px;
  z-index: 0;
}

.leaflet-div-icon {
  background-color: transparent;
  color: rgba(131, 0, 81, var(--tw-text-opacity));
  border: none;
}

.az-medical-centers {
  &__info-box {
    @apply relative top-0 left-0 h-full w-11/12 md:w-5/12 bg-white px-4 py-6;

    z-index: 1000;
    overflow: auto;
  }

  &__heading-box {
    @apply flex justify-between items-center;
  }

  &__heading {
    @apply font-lexia text-dark font-bold;

    font-size: 22px;
  }

  &__info-table {
    @apply grid mt-8;

    grid-template-columns: 35% 55%;
    grid-row-gap: 20px;

    @media (max-width: 1299px) {
      grid-template-columns: 100%;
    }
  }

  &__info-table-opening-hours {
    @apply grid mt-8;

    grid-template-columns: 100%;
    grid-row-gap: 20px;
  }

  &__info-label {
    @apply font-helvetica-az font-bold text-sm text-dark;
  }

  &__info-text {
    @apply font-helvetica-az text-sm text-dark;
  }

  &__icon-close {
    @apply text-gray;
  }

  &__icon-search {
    @apply text-primary mb-1 cursor-pointer;
  }

  &__days-grid {
    @apply grid mt-1;

    grid-template-columns: 34% minmax(20px, 17%) minmax(20px, 17%) minmax(20px, 17%) minmax(20px, 17%);
    grid-row-gap: 2px;
  }

  &__action-container {
    @apply w-full flex-col my-8 lg:flex lg:flex-row justify-between;
  }

  &__actions-row {
    @apply flex-col my-8 lg:flex w-full lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4;
  }

  &__dropdown {
    @apply w-full whitespace-nowrap lg:w-6/12;
  }

  &__spinner {
    @apply w-6 mx-auto;
  }

  &__type-container {
    @apply flex flex-col w-full md:w-8/12 lg:w-full;

    margin-top: 32px;
  }

  &__type-label {
    @apply flex w-full mb-2;
  }

  &__t1 {
    @apply text-xs;
  }

  &__t3 {
    @apply text-xs ;
  }
}
