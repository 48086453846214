.az-admin-questionnaires {

  &__row-wrapper {
    @apply flex justify-between items-end;
  }

  &__search {
    min-width: 256px;
  }

  &__new-survey {
    @apply w-64;
  }

  &__questionnaires-container {
    @apply flex flex-wrap gap-8 justify-start items-center mt-10;

    &--loading {
      @apply justify-center;
    }
  }

  &__spinner {
    ///margin is used to increase parent height
    @apply w-8 block my-16;
  }

  &__error-message {
    @apply font-helvetica-az font-normal text-base text-dark my-14;
  }
}
