@use 'sass:math';

//no-invalid-position-at-import-rule not working for this line (???)
//stylelint-disable-next-line
@import '../../../utils/color-variants';

.az-highlight-block {
  $root-selector: &;

  @apply flex flex-col items-center relative;

  @include color-variants(('color'));

  &:nth-child(n+2) {

    #{$root-selector}__highlight-text {
      &::before {
        $height: 26px;

        @apply absolute left-0 h-5 w-0.5;

        height: $height;
        top: calc(50% - #{math.div($height, 2)});
        content: '';
      }

      @media (max-width: 767px) {
        &::before {
          @apply absolute top-0 h-0.5 w-10;

          left: calc(50% - 20px);
          margin-top: -100px;
          content: '';
        }
      }
    }
  }

  &__highlight-text {
    @apply block relative font-lexia font-bold mt-4 w-full text-center;

    color: inherit;
    font-size: 68px;

    @media (min-width: 1024px) {
      font-size: 72px;
    }

    &::before {
      @include color-variants(('background-color'), $root-selector, &);
    }
  }

  &__title {
    @apply font-helvetica-az text-dark text-2xl mt-2.5 font-medium px-24 text-center;
  }
}

@media (max-width: 1024px) {
  .az-highlight-block {
    margin-bottom: 60px;
  }
}
