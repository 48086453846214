.az-alert {
  @apply flex flex-col w-10/12 px-7 pt-8 pb-12 justify-center rounded-2xl shadow-md bg-white transition-opacity duration-100 opacity-0 lg:flex-row-reverse lg:py-6 fixed left-1/2 z-10;

  transform: translate(-50%, 0);

  &--opaque {
    @apply opacity-100;
  }

  &__close-button {
    @apply flex justify-end self-end mb-1 relative right-0 lg:block;

    &:hover {
      @apply opacity-70;
    }
  }

  &__icon-description {
    @apply w-full flex flex-col self-center lg:flex-row lg:justify-center lg:ml-3.5;

    &--success {
      @apply flex text-active;
    }

    &--error {
      @apply text-red-600;
    }

    &--info {
      @apply text-dark lg:space-x-12;
    }
  }

  &__icon {
    @apply self-center justify-end mb-7 lg:mb-0 lg:mr-3;
  }

  &__description {
    @apply text-center font-medium text-sm font-helvetica-az self-center pt-1.5;
  }

  &__link {
    @apply font-helvetica-az font-bold text-dark text-sm underline self-center mt-12 lg:mt-1;

    &:hover {
      @apply opacity-70;
    }

    &--primary {
      @apply text-primary;
    }
  }
}
