.az-data-table--admins {
  $root-selector: '.az-data-table';

  #{$root-selector}__admins-data-row {
    @apply w-full border-solid border-gray border-b;

    &:hover {
      @apply bg-hover-gray underline;
    }

    &--disabled {
      @apply bg-hover-gray;

      &:hover {
        text-decoration: none;
      }
    }
  }

  #{$root-selector}__data-cell {
    @apply cursor-pointer;

    &--disabled {
      @apply cursor-auto;
    }
  }
}
