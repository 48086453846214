.az-resource {
  @apply block w-full mb-16 mx-8;

  text-align: left !important;

  &__wrapper {
    @apply flex flex-col items-start justify-start;
  }

  &__media {
    @apply w-full border rounded-2xl overflow-hidden;
  }

  &__body {
    @apply w-full mt-5;
  }

  &__title {
    @apply font-helvetica-az font-bold text-xl mb-2;
  }

  &__excerpt {
    @apply font-helvetica-az text-base font-normal text-gray-dark h-14;
  }

  &__pathology-chip {
    @apply rounded-2xl py-1 px-3 inline-block bg-gray text-dark mr-2 mb-2;

    max-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
