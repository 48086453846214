.az-admin-questionnaire-detail {

  &__title-dropdown {
    @apply mt-0;
  }

  &__intro {
    @apply w-7/12 mb-10;
  }

  &__titles {
    @apply flex self-start space-x-5;
  }

  &__input {
    @apply w-7/12;
  }

  &__drop {
    @apply w-6/12;
  }

  &__question {
    @apply mb-6;
  }

  &__new-question {
    @apply w-60 mb-10;
  }
}
