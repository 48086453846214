.az-burger-menu {
  @apply bg-gradient-to-br from-primary to-secondary justify-center px-2 opacity-0 transition-opacity h-screen pointer-events-none;

  &--open {
    @apply opacity-100 pointer-events-auto pointer-events-auto;
  }

  &__icon {
    @apply relative left-7 self-center pt-5;
  }

  &__links {
    @apply flex flex-col pt-24 px-7;

    &-disabled {
      @apply hidden;
    }
  }

  &__link {
    @apply flex font-helvetica-az font-normal text-base text-white self-center pb-20;

    &:hover {
      @apply underline;
    }
  }

  &__logout {
    @apply font-helvetica-az font-normal underline text-white text-base self-center pb-20;
  }

  &__button {
    @apply flex self-center mb-20;
  }
}
