@import './src/utils/ratio-classes';

.az-image {

  &__picture {
    @apply block relative;

    @include ratio-classes;
  }

  &__img {
    @apply absolute w-full h-full object-cover;
  }
}
