.az-logo {
  @apply bg-no-repeat bg-center bg-cover;

  &--full {
    background-image: var(--logo-full);
  }

  &--white {
    background-image: var(--logo-white);
  }

  &--white-yellow {
    background-image: var(--logo-white-yellow);
  }
}
