// customize Toastify as indicated in docs
.toastify {
  &__toast {
    min-height: 0;
    padding: 10px 16px;
    width: 450px;

    &--success {
      background: var(--success-color);
    }

    &--error {
      background: var(--error-color);
    }
  }

  &__toast-container {
    &--top-center {
      left: calc(50% - 225px);
      top: 70px;
      transform: translate(0);
    }
  }

  &__toast-body {
    font-family: var(--font-family-regular);
    line-height: 16px;
    padding: 0;
  }

  &__close-button {
    @apply self-center;
  }

  // custom classes
  &__icon-container {
    @apply flex items-center;
  }

  &__icon {
    margin-right: 17px;
  }
}
