.az-input {

  &__input-wrapper {
    @apply flex border-solid border border-gray justify-between self-start rounded-lg mt-2 pr-3 bg-white items-center;

    max-height: 45px;

    &--error {
      @apply border-error bg-error-alpha;
    }
    &--valid {
      @apply border-active bg-active-alpha;
    }

    &--disabled {
      @apply bg-gray-light text-gray-dark;
    }
  }

  &__input {
    @apply w-full px-3 py-3 bg-transparent text-dark text-sm items-center font-helvetica-az font-normal outline-none;
  }
}
