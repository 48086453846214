.az-homepage {
  &__resource-label {
    @apply font-lexia font-bold text-3xl text-primary mt-4;
  }

  &__resource-content {
    @apply flex justify-center items-center gap-7 mt-10;
  }

  &__resource {
    @apply w-full;
  }

  &__resource.az-resource {
    @apply mx-0 mb-4;
  }

  &__resource-error-message {
    @apply font-lexia text-dark text-lg;
  }

  &__section-marker {
    @apply w-10;
  }

  &__spinner {
    @apply w-8 block my-16 mx-auto;
  }

  &__go-to-cta {
    @apply mx-auto;

    width: 240px !important;

    &--map {
      @apply mt-9 mb-24 mx-auto;

      width: 240px !important;
    }
  }

  &__show-all-btn {
    @apply mx-auto mt-8 mb-24;

    width: 240px !important;
  }

  &__video-container {
    @apply flex justify-center items-center cursor-pointer  mt-14 mx-auto  lg:mt-28;

    max-width: 1080px;
    // Replaced with embedded video-poster
    //background-image: var(--video-placeholder);

    &:hover {
      @apply opacity-70;
    }
  }

  &__video {
    width: 100%;
    height: 100%;
  }

  &__video-link {
    @apply w-full h-full relative;
  }
}
