.az-page-not-found {
  @apply my-52;

  &__title {
    @apply bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text text-center text-9xl font-bold;
  }

  &__subtitle {
    @apply text-center font-medium text-gray-dark;
  }
}
