.az-active-questionnaire {

  &__patient-data {
    @apply my-11;
  }

  &__titles-container {
    @apply self-center space-y-3 mb-9;
  }

  &__title {
    @apply font-lexia font-bold text-dark;

    font-size: 22px;
  }

  &__subtitle {
    @apply font-helvetica-az font-normal text-base text-dark;
  }

  &__instructions-container {
    @apply mb-28;
  }

  &__instructions-title {
    @apply font-helvetica-az font-bold text-lg text-dark;
  }

  &__instructions {
    @apply font-helvetica-az font-normal text-base text-dark;
  }

  &__buttons {
    @apply flex self-center justify-center mb-20 space-x-5;
  }

  &__button {
    @apply w-40;
  }
}
