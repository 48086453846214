.az-page--homepage {
  $root-selector: '.az-page';
  white-space: pre-line;

  @apply md:grid md:grid-cols-1 md:min-h-screen;

  @screen md {
    grid-template-rows: min-content auto min-content;
  }

  #{$root-selector}__heading {
    @apply relative from-primary to-secondary bg-no-repeat;

    background-image: url('../../../themes/base/assets/Monogramma_bg_2.png'), linear-gradient(to bottom right, var(--tw-gradient-stops));
    background-position: bottom -150px right -150px, top left;
    grid-row: 1 / span 1;
    background-size: 420px, auto;

    @screen lg {
      background-image: url('../../../themes/base/assets/Monogramma_bg_1.png'), url('../../../themes/base/assets/Monogramma_bg_2.png'), linear-gradient(to bottom right, var(--tw-gradient-stops));
      background-position: bottom left, bottom right -35px, top left;
      background-size: 50%, 40%, auto;
    }
  }

  #{$root-selector}__heading-nike-logo {
    @apply absolute bottom-0 left-0;
  }

  #{$root-selector}__container {
    @apply self-center mx-4 md:mx-16 lg:mx-24;

    &--main {
      grid-row: 2 / span 1;
    }
  }

  #{$root-selector}__footer {
    grid-row: 3 / span 1;
  }
}
