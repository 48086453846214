@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind layer;

@layer base {
  @font-face {
    font-family: Lexia;
    src: url('assets/fonts/Lexia_Lt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Lexia;
    src: url('assets/fonts/Lexia_Bd.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: Helvetica-AZ;
    src: url('assets/fonts/HelveticaNeue-Light.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Helvetica-AZ;
    src: url('assets/fonts/HelveticaNeue-Roman.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Helvetica-AZ;
    src: url('assets/fonts/HelveticaNeue-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
}
