.az-sidebar {
  @apply w-auto h-full bg-primary py-4 self-center overflow-scroll;

  &__image-container {
    @apply flex justify-center mt-3 mb-6 cursor-pointer;
  }

  &__image-wrapper {
    @apply flex justify-center w-20 h-20 items-center rounded-full bg-white opacity-70;
  }

  &__profile-pic-wrapper {
    @apply justify-center w-20 h-20 items-center rounded-full bg-white object-cover;
  }

  &__admin-name-image {
    @apply flex justify-center text-primary font-helvetica-az font-medium text-2xl uppercase;

    margin-top: 6px;
  }

  &__admin-name {
    @apply flex justify-center text-base font-helvetica-az font-bold text-white;
  }

  &__label {
    @apply flex justify-center text-sm font-helvetica-az font-light text-white;
  }

  &__buttons-container {
    @apply flex flex-col w-full border-solid border-white border-t border-opacity-20 mt-8 mb-16;
  }

  &__button {
    @apply h-full w-full flex justify-start font-helvetica-az font-normal text-white pl-6 py-4 border-solid border-white border-b border-opacity-20;

    &--selected {
      @apply bg-white bg-opacity-20;
    }

    &:hover {
      @apply bg-white bg-opacity-20;
    }
  }

  &__button-download {
    @apply bg-white text-primary mt-20 mx-auto;

    &:hover {
      @apply bg-white bg-opacity-20 text-white;
    }

    font-size: 12px;
    max-width: 240px;
  }

  &__icon {
    @apply text-white self-center mb-1 mr-5;
  }

  &__profile {
    @apply w-full flex justify-center font-helvetica-az font-normal text-sm underline text-white self-center mr-0.5 border-r border-solid border-white;

    &:hover {
      @apply opacity-70;
    }
  }

  &__logout {
    @apply w-full flex justify-center font-helvetica-az font-normal text-sm underline text-white self-center ml-0.5;

    &:hover {
      @apply opacity-70;
    }
  }
}
