.az-components {
  @apply px-3 py-2 w-full pt-16;

  &__title {
    @apply font-lexia font-bold text-3xl;
  }

  &__subtitle {
    @apply font-lexia font-bold text-xl mt-10 mb-4;
  }

  &__sub-subtitle {
    @apply font-lexia font-normal text-lg mt-8 mb-4;
  }

  &__button-wrapper {
    @apply flex flex-col lg:flex-row justify-between self-center w-full;
  }
}
