.az-bread-crumb {
  @apply flex justify-start items-center;

  &__item {
    @apply font-helvetica-az text-sm text-dark font-bold;

    &:last-child {
      @apply font-normal;
    }
  }

  &__item-separator {
    @apply w-2 mx-1 mb-1 text-sm;
  }
}
