.az-resources {
  &__filters-box {
    @apply mt-12;
  }

  &__pathology-chip {
    @apply rounded-2xl py-1 px-3 inline-block bg-gray text-dark mr-2 mb-2 cursor-pointer;

    max-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--selected {
      @apply bg-primary text-white;
    }
  }

  &__spinner {
    @apply w-8 block my-16 mx-auto;
  }

  &__resource-container {
    @apply inline-block mr-6;

    width: 28%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__resource {
    @apply w-full;
  }
}
