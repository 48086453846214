.az-admin-content-detail {

  &__row-wrapper {
    @apply w-full flex mb-8 space-x-6;
  }

  &__radio-title {
    @apply mb-5 text-dark text-base;
  }

  &__radio-label {
    @apply pl-4 text-dark text-sm;
  }

  &__drop {
    @apply w-4/12;
  }

  &__description {
    @apply w-4/12;
  }

  input[type='radio'] {
    width: 18px;
    height: 18px;
    top: 3px;
    position: relative;
  }
}
