.az-textarea {

  &__wrapper {
    @apply flex border-solid border border-gray justify-between self-start rounded-lg py-2 px-3 bg-white items-center;

    &--error {
      @apply border-error bg-error-alpha;
    }

    &--disabled {
      @apply bg-gray-light text-gray-dark;
    }
  }

  &__input {
    @apply bg-transparent w-full h-full outline-none resize-none text-dark text-sm items-center font-helvetica-az font-normal;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
