@import './src/utils/color-variants';

.az-page-template--auth-base {
  $root-selector: '.az-page-template';

  @apply min-h-screen md:grid md:grid-cols-1 md:min-h-screen bg-gray-light;

  @screen md {
    grid-template-rows: min-content auto min-content;
  }

  #{$root-selector}__header {
    @apply top-0 sticky z-10;

    grid-row: 1 / span 1;
  }

  #{$root-selector}__heading {
    @apply flex-col justify-between items-start mt-9 md:flex md:flex-row;
  }

  #{$root-selector}__cta-text {
    @apply font-helvetica-az font-bold text-sm underline text-gray-dark mr-16;

    &:hover {
      @apply opacity-70;
    }
  }

  @media (max-width: 767px) {
    #{$root-selector}__cta {
      @apply mx-auto mt-6;

      max-width: 220px;
    }
  }

  @media (max-width: 767px) {
    #{$root-selector}__heading--add-mobile {
      @apply flex flex-row;

      #{$root-selector}__cta {
        @apply mx-0 mt-0;
      }
    }
  }

  #{$root-selector}__title {
    @apply font-lexia font-bold text-3xl text-primary mb-3;
  }

  #{$root-selector}__breadcrumb {
    @apply mt-3.5;
  }

  #{$root-selector}__main {
    @include color-variants(('background-color'));

    margin-top: 1.5rem;
    grid-row: 2 / span 1;
  }

  #{$root-selector}__container {
    @apply px-10 lg:pl-28 lg:pr-24;
  }

  #{$root-selector}__alert {
    @apply top-20 right-3 left-3 lg:top-32 fixed;
  }

  #{$root-selector}__footer {
    grid-row: 3 / span 1;
  }
}
