@import './src/utils/color-variants';

.az-page-template--base {
  $root-selector: '.az-page-template';

  @apply min-h-screen md:grid md:grid-cols-1 md:min-h-screen bg-gray-light;

  @screen md {
    grid-template-rows: min-content auto min-content;
  }

  #{$root-selector}__header {
    @apply top-0 sticky z-10;

    grid-row: 1 / span 1;
  }

  #{$root-selector}__main {
    @include color-variants(('background-color'));

    margin-top: 1.5rem;
    grid-row: 2 / span 1;
  }

  #{$root-selector}__container {
    @apply px-10 lg:px-28;
  }

  #{$root-selector}__alert {
    @apply top-20 right-3 left-3 lg:top-32 fixed px-6 lg:px-24;
  }

  #{$root-selector}__admin-alert {
    @apply top-4 right-3 left-3 fixed;
  }

  #{$root-selector}__footer {
    grid-row: 3 / span 1;
  }
}
