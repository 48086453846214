.az-burger-button {
  @apply flex flex-col h-10 w-10 rounded items-center group;

  &__line {
    @apply h-0.5 w-6 mt-1 rounded-full bg-dark transition ease-in-out transform duration-300 opacity-100;

    &--light {
      @apply bg-white;
    }
  }

  &--open {

    .az-burger-button__line--one {
      @apply rotate-45 translate-y-1;
    }

    .az-burger-button__line--two {
      @apply opacity-0;
    }

    .az-burger-button__line--three {
      @apply -rotate-45 -translate-y-2;
    }
  }
}
