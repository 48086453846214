.az-dropdown {
  @apply flex-col self-center items-center w-full border-solid border border-gray justify-between rounded-lg bg-white transform duration-700 ease-in-out mt-2;

  &--error {
    @apply border-solid border border-error bg-error-alpha;
  }

  &--disabled {
    @apply bg-gray-light text-gray-dark pointer-events-none;
  }

  &__label {
    @apply text-dark;
  }

  &__select {
    @apply flex justify-between w-full items-center;
  }

  &__title {
    @apply font-helvetica-az font-bold text-sm text-primary;
  }

  &__value {
    @apply w-full px-3 py-3 bg-transparent text-dark text-sm items-center font-helvetica-az font-normal outline-none cursor-pointer;
  }

  &__option-list {
    @apply w-full flex flex-col;
  }

  &__option {
    @apply w-full py-4 px-3 font-helvetica-az font-normal text-sm text-dark self-start text-left;

    &:hover {
      @apply bg-primary text-white opacity-70;
    }
  }
}
